/* =============
   General
============= */

html {
  position: relative;
  min-height: 100%;
}

p{
  line-height: 1.7;
}

.h1,.h2,.h3,.h4,.h5,.h6,
h1,h2,h3,h4,h5,h6 {
  font-weight: 500;
    line-height: 1.2;
}

a {
  text-decoration: none !important;
}


label {
  font-weight: 500;
  margin-bottom: $form-label-margin-bottom;
}

// Small

.small, small{
  font-weight: 400;
}

// blockquote

.blockquote {
  padding: 10px 20px;
  border-left: 4px solid $gray-300;
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid $gray-300;
  text-align: right;
}


// container

.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
  @media (min-width: 1200px){
      max-width: 1140px;
  }
}

// row

.row{
  > * {
      position: relative;
  }
}

.main_content{
    margin-top: 180px !important;
    margin-bottom: 100px;
}


@media (max-width: 992px) {
    .main_content {
        margin-top: 100px !important;
    }
    
  }